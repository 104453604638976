@import "./styles/utils.scss";
@import "./styles/semantic-ui.scss";

body {
  background-color: #f7f7f7;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.description p {
  margin: 0;
}

.menu-wrapper {
  display: flex;
  flex-direction: column;

  &--logoLeft {
    flex-direction: row;

    .menu--header {
      text-align: left;
      flex: 1;
    }

    .ui.menu.menu--hover {
      width: auto;
      margin-top: auto;

      .right.menu {
        margin-left: 0;
      }
    }
  }
}

.menu--header {
  position: relative;
  text-align: center;

  img {
    max-height: 60px;
    margin: 4px 8px;
    vertical-align: middle;
  }

  .dropdown--languages {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__search--bar {
    width: 100%;

    &-shrink {
      width: 30rem;
    }
  }
}

.screen > .ui.grid {
  margin-top: 0;
}

.ui.grid {
  .column {
    .ui.segments > .ui.segment {
      margin-top: 0;
      margin-bottom: 0;
    }

    .ui.segment {
      margin: 2rem 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.ui.card > :first-child,
.ui.cards > .card > :first-child.content--rounded-image {
  border-radius: 0.28571429rem !important;
}

.ui.card > .content.borderless {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

h2.ui.header,
h3.ui.header {
  color: #2185d0;
}

.card--event {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.image-with-shadow {
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.ui.menu.menu--hover {
  width: 100%;

  .dropdown.item > .menu {
    display: none;
  }

  .dropdown.item:hover > .menu {
    display: block;
  }

  .dropdown.item > .menu,
  .ui.text.menu .dropdown.item > .menu {
    margin-top: 0;
  }

  .item > .ui.label {
    margin-top: -3px;
    margin-bottom: -3px;
  }
}

#root > .ui.container {
  padding-bottom: 2rem;

  > .ui.header {
    color: white;
  }
}

.Toastify__toast-body {
  color: black;
}

.swal2-title {
  font-size: 18px !important;
}

.ui.container > .ui.grid {
  margin-top: 0;
}

@media (max-width: 1200px) {
  .menu > .item {
    .large.monitor {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .menu--header {
    .dropdown--languages {
      display: none !important;
    }
  }

  .ui.grid {
    display: block;

    > .stretched.row {
      padding: 0em;
      display: block;

      > .column {
        margin: 8px 0em;
        width: 100% !important;
      }
    }

    > [class*="wide"].column {
      width: 100% !important;
    }

    .ui.cards {
      display: block !important;
      margin: 0px !important;
    }
  }

  .ui.cards {
    margin: 10px 0px;
    display: block !important;

    > .card {
      width: calc(100% - 2em) !important;
    }
  }

  .ui.form:not(.unstackable) .fields:not(.unstackable) > .field {
    margin-bottom: 1em;
  }

  .menu--header img {
    width: 100%;
    margin: 0px;
    max-height: max-content;
  }
}

.card__read-more {
  float: right;
  margin-top: 8px;
}

.items--group {
  margin-bottom: 16px;

  .ui.cards {
    margin-bottom: 0;
  }
}

// Remove double-shadows
.ui.segment > .ui.message {
  background: none;
  box-shadow: none;
  padding: 0;
}

.column > .ui.segments.block--html {
  > .ui.segment {
    min-height: 100%;
  }
}

.ui.segments.block--video {
  .ui.segment:first-child {
    margin-bottom: 0;
  }

  .ui.segment:not(:first-child) {
    padding: 0;
    margin-top: 0;
  }
}

.tags > .ui.label {
  margin-left: 0;
  margin-bottom: 2px;
}

/** Cascade flex height **/
html,
body {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#root > .ui.container {
  flex: 1 0 auto; // Grows but doesn't shrink. (to keep normal scroll handling)
  display: flex;
  flex-direction: column;
}

a.item:focus {
  outline: none;
}

.ui.list {
  > .item.item--table {
    display: table;
    width: 100%;
    table-layout: fixed;

    > .image-container {
      display: table-cell;
    }

    > .content {
      width: inherit;
      padding-left: 1em;
      display: table-cell !important;
    }
  }
}

.full-size-block {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  flex: 1 0 auto;
}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.leaflet-container {
  width: 100%;
  background-color: #f4f4f4 !important;
  height: 85vh;
  z-index: 100;
}

/* Badge Toast */
.Toastify__toast-container.badge-notification {
  .Toastify__toast {
    padding: 0;
    border-radius: 2em;
    height: 16em;
  }

  .achievement-notify {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1em;

    .text {
      font-weight: bold;
      font-size: 1.3em;
      line-height: 1.5em;
      text-align: center;
      color: #FFFFFF;
      padding: 0 1.5em;
    }

    .unlock-image {
      height: 9em;
      border: solid #FFFFFF 0.01em;
      border-radius: 50%;
      margin-top: 1.2em;
      margin-bottom: 0.8em;
    }
  }

  .Toastify__close-button {
    position: absolute;
    top: 1em;
    right: 1em;
    color: #FFFFFF;
    opacity: 0.5;

    & > svg {
      height: 2em;
      width: 2em;
      font-weight: 100;
    }
  }

  .Toastify__progress-bar {
    background: #FFFFFF;
  }
}

.page.page--home.page--cms {
  .block--cms-files {

    .content--image > .ui.image {
      height: 10em;
    }
  }
}
